<template>
    <div
        :class="[
            'add-materials-modal',
            {
                '--open': open
            }
        ]"
    >
        <b-sidebar
            id="add-materials-modal"
            :visible="open"
            shadow
            right
            bg-variant="white"
            backdrop
            no-close-on-backdrop
            no-close-on-esc
        >
            <template #header>
                <Header />
            </template>

            <template>
                <Content />
            </template>

            <template #footer>
                <Footer v-if="!flags.isMultipleOrders" />
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BSidebar, VBToggle } from "bootstrap-vue";
    import Header from "./Header.vue";
    import Content from "./Content.vue";
    import Footer from "./Footer.vue";
    import { mapGetters, mapMutations } from "vuex";

    export default {
        components: {
            Header,
            Content,
            Footer,
            BSidebar
        },
        directives: {
            "b-toggle": VBToggle
        },
        computed: {
            ...mapGetters("production_progress", ["flags"])
        },
        props: {
            open: {
                type: Boolean,
                required: true,
                default: true
            }
        },
        methods: {
            ...mapMutations("production_progress", [])
        },
        mounted() {}
    };
</script>

<style lang="scss">
    body:has(.add-materials-modal.--open) {
        overflow: hidden;
    }

    #add-materials-modal {
        min-width: 600px;
        max-width: 600px;
    }

    @media (max-width: 768px) {
        #add-materials-modal {
            min-width: 100dvw;
            max-width: 100dvw;
        }
    }
</style>
