<template>
    <div class="add-materials-modal-header__wrapper">
        <div class="add-materials-modal-header__container">
            <div class="add-materials-modal-header__container-left">
                <div
                    @click="backToRecordMaterialsModal()"
                    class="add-materials-modal-header__back-button-container"
                >
                    <ArrowIcon />
                </div>

                <span class="add-materials-modal-header__title">
                    {{ $t("ProductionProgress.AddMaterials") }}
                </span>
            </div>

            <span
                @click="backToRecordMaterialsModal()"
                class="add-materials-modal-header__close-icon"
            >
                <close-icon />
            </span>
        </div>
    </div>
</template>

<script>
    import { BSidebar, VBToggle } from "bootstrap-vue";
    import CloseIcon from "@core/assets/formatted-icons/close-icon.svg";
    import ArrowIcon from "@core/assets/icons/arrow-icon.svg";
    import { mapActions } from "vuex";

    export default {
        components: {
            BSidebar,
            CloseIcon,
            ArrowIcon
        },
        directives: {
            "b-toggle": VBToggle
        },
        methods: {
            ...mapActions("production_progress", ["backToRecordMaterialsModal"])
        }
    };
</script>

<style lang="scss">
    #add-materials-modal {
        .b-sidebar-header {
            padding: 0 !important;
        }
    }
</style>

<style lang="scss" scoped>
    #add-materials-modal {
        .add-materials-modal-header__wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .add-materials-modal-header__container {
                display: flex;
                padding: 15.5px 29px;
                border-bottom: 1px solid #cfc4be;
                flex-wrap: nowrap;
                align-items: center;
                width: 100%;
                gap: 16px;

                .add-materials-modal-header__container-left {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    width: 100%;

                    .add-materials-modal-header__back-button-container {
                        padding: 8px;
                        height: fit-content;
                        cursor: pointer;
                        display: flex;

                        svg {
                            min-width: 12px;
                            max-width: 12px;
                            min-height: 12px;
                            max-height: 12px;
                            fill: #974900;
                            transform: rotate(180deg);
                        }
                    }

                    .add-materials-modal-header__title {
                        font-size: 26px;
                        font-weight: 600;
                        line-height: 36px;
                        color: #4c4541;
                        width: 100%;
                    }
                }

                .add-materials-modal-header__close-icon {
                    cursor: pointer;
                    display: flex;
                    height: fit-content;

                    svg {
                        min-width: 16px;
                        max-width: 16px;
                        min-height: 16px;
                        max-height: 16px;
                        fill: #4c4541;
                    }
                }

                .add-materials-modal-header__separator {
                    width: 100%;
                    height: 1px;
                    background-color: #e5e5e5;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #add-materials-modal {
            .add-materials-modal-header__back-button-container {
                padding: 14px !important;
                svg {
                    min-width: 18px !important;
                    max-width: 18px !important;
                    min-height: 18px !important;
                    max-height: 18px !important;
                }
            }

            .add-materials-modal-header__title {
                font-size: 36px !important;
                line-height: 50px !important;
            }

            .add-materials-modal-header__close-icon {
                svg {
                    min-width: 24px !important;
                    max-width: 24px !important;
                    min-height: 24px !important;
                    max-height: 24px !important;
                }
            }
        }
    }

    @media (max-width: 570px) {
        #add-materials-modal {
            .add-materials-modal-header__container {
                padding: 15.5px 16px !important;
                .add-materials-modal-header__title {
                    font-size: 18px !important;
                    line-height: 26px !important;
                }

                .add-materials-modal-header__close-icon {
                    svg {
                        min-width: 16px !important;
                        max-width: 16px !important;
                        min-height: 16px !important;
                        max-height: 16px !important;
                    }
                }
            }
        }
    }
</style>
