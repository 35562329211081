<template>
    <div
        id="add-material-card"
        @click="
            appointmentType === 'single'
                ? $emit('change', { ...material, checked: !checked })
                : $emit('click', material)
        "
    >
        <div class="add-material-card__content">
            <div class="add-material-card__row">
                <span class="add-material-card__label">
                    {{ $t("ProductionProgress.Material") }}
                </span>

                <div
                    :id="`tooltip-material-name-${material.id}`"
                    class="add-material-card__text-wrapper"
                >
                    <span class="add-material-card__text">
                        {{ material.name }}
                    </span>
                </div>
                <sys-tooltip
                    :target="`tooltip-material-name-${material.id}`"
                    triggers="hover blur"
                    placement="top"
                    boundary-padding="0"
                >
                    {{ material.name }}
                </sys-tooltip>
            </div>
            <div class="add-material-card__row">
                <span class="add-material-card__label">
                    {{ $t("ProductionProgress.Code") }}
                </span>
                <span class="add-material-card__text">{{ material.code }}</span>
            </div>
        </div>

        <div
            class="add-material-card__icon-container"
            v-if="appointmentType === 'multiple'"
        >
            <arrow-icon />
        </div>

        <div
            class="add-material-card__checkbox-container"
            v-if="appointmentType === 'single'"
        >
            <sys-checkbox
                :size="handleChekboxSize()"
                :checked="checked"
            />
        </div>
    </div>
</template>

<script>
    import ArrowIcon from "@core/assets/icons/arrow-icon.svg";
    import SysCheckbox from "@/@core/components/sys-checkbox";
    import { mapGetters } from "vuex";
    import { BTooltip } from "bootstrap-vue";
    import SysTooltip from "@/@core/components/sys-tooltip";

    export default {
        components: {
            ArrowIcon,
            SysCheckbox,
            BTooltip,
            SysTooltip
        },
        computed: {
            ...mapGetters("app", ["windowWidth"])
        },
        props: {
            material: {
                type: Object,
                default: null
            },
            appointmentType: {
                type: String,
                default: "single"
            },
            checked: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleChekboxSize() {
                if (this.windowWidth >= 768) return "lg";
                if (this.windowWidth < 768 && this.windowWidth > 570) return "xl";

                return "md";
            }
        }
    };
</script>

<style lang="scss" scoped>
    #add-material-card {
        display: flex;
        width: 100%;
        padding: 31.5px 29px;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid #cfc4be;
        cursor: pointer;

        .add-material-card__content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: calc(100% - 16px - 24px);

            .add-material-card__row {
                display: flex;
                gap: 16px;
                width: fit-content;
                width: 100%;

                .add-material-card__label {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    max-width: calc(50% - 8px);
                    min-width: calc(50% - 8px);
                }

                .add-material-card__text-wrapper {
                    display: flex;
                    width: fit-content;
                    overflow: hidden;
                    max-width: calc(50% - 8px);
                }
                .add-material-card__text {
                    color: #4c4541;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;

                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .add-material-card__icon-container {
            height: fit-content;

            svg {
                min-width: 24px;
                max-width: 24px;
                min-height: 24px;
                max-height: 24px;
                fill: #4c4541;
            }
        }
    }

    @media (max-width: 768px) and (min-width: 571px) {
        #add-material-card {
            .add-material-card__content {
                .add-material-card__row {
                    .add-material-card__label,
                    .add-material-card__text {
                        font-size: 22px !important;
                        line-height: 30px !important;
                    }
                }
            }

            .add-material-card__icon-container {
                height: fit-content;

                svg {
                    min-width: 24px;
                    max-width: 24px;
                    min-height: 24px;
                    max-height: 24px;
                    fill: #4c4541;
                }
            }
        }
    }

    @media (max-width: 570px) {
        #add-material-card {
            padding: 31.5px 16px !important;

            .add-material-card__content {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: calc(100% - 16px - 16px);

                .add-material-card__row {
                    flex-direction: column;
                    gap: 4px;

                    .add-material-card__text-wrapper {
                        max-width: 100% !important;
                    }
                    .add-material-card__label {
                        font-size: 12px !important;
                        line-height: 16px !important;
                        max-width: 100%;
                        min-width: 100%;
                    }

                    .add-material-card__text {
                        font-size: 14px !important;
                        line-height: 20px !important;
                        max-width: 100%;
                        min-width: 100%;
                    }
                }
            }

            .add-material-card__icon-container {
                height: fit-content;

                svg {
                    min-width: 16px !important;
                    max-width: 16px !important;
                    min-height: 16px !important;
                    max-height: 16px !important;
                }
            }

            .add-material-card__checkbox-container {
                height: 100%;
                display: flex;
                align-items: flex-start;
            }
        }
    }
</style>
