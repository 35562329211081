export function normalizeString(str) {
    return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
}

export function filterMaterials(filters, materials, updateFilteredList) {
    // Atualiza o filtro
    const updatedFilters = [...filters];

    if (updatedFilters.length === 0) {
        // Se não há filtros, retorna todos os materiais
        updateFilteredList([...materials]);
        return;
    }

    // Normaliza os termos de pesquisa
    const searchTerms = updatedFilters.map(normalizeString);

    // Filtra a lista de materiais
    const filteredList = materials.filter((item) => {
        const normalizedItemName = normalizeString(item.name);
        return searchTerms.some((term) => normalizedItemName.includes(term));
    });

    // Chama a função para atualizar a lista filtrada
    updateFilteredList(filteredList);
}
