<template>
    <div
        :class="[
            'sys-checkbox',
            size && `normal-${size}`,
            {
                checked,
            },
        ]"
        @click="$emit('click', !checked)"
    >
        <span class="sys-checkbox__icon"><CheckIcon /></span>
    </div>
</template>

<script>
import CheckIcon from "@/@core/assets/formatted-icons/check-icon.svg";

export default {
    components: {
        CheckIcon,
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "md",
        },
    },
};
</script>

<style lang="scss" scoped>
.sys-checkbox {
    display: flex;
    background-color: #fff;
    border: 1px solid #cfc4be;
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    .sys-checkbox__icon {
        display: flex;
        .sys-checkbox__icon {
            svg {
                fill: #fff;
            }
        }
    }

    &.checked {
        background-color: #974900;
    }

    &.normal-xl {
        min-width: 30px;
        max-width: 30px;
        min-height: 30px;
        max-width: 30px;

        .sys-checkbox__icon {
            min-width: 20px;
            max-width: 20px;
            min-height: 20px;
            max-width: 20px;
        }
    }

    &.normal-lg {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-width: 24px;

        .sys-checkbox__icon {
            min-width: 16px;
            max-width: 16px;
            min-height: 16px;
            max-width: 16px;
        }
    }

    &.normal-md {
        min-width: 18px;
        max-width: 18px;
        min-height: 18px;
        max-width: 18px;

        .sys-checkbox__icon {
            min-width: 12px;
            max-width: 12px;
            min-height: 12px;
            max-width: 12px;
        }
    }
}
</style>
